import "../../assets/scss/app.scss";
import gsap from 'gsap';
import {
  ScrollTrigger
} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  Navigation,
  Pagination
} from 'swiper/modules';

class Main {
  constructor() {


    const mediaQuery = window.matchMedia('(max-width: 768px)')


    // const mySwiper = new Swiper('.card02 .swiper', {
    //   modules: [Navigation, Pagination],
    //   slidesPerView: '1',
    //   // spaceBetween: 16,
    //   grabCursor: true,
    //   autoHeight: true,
    //   pagination: {
    //     el: '.card02 .swiper-pagination',
    //     type: 'bullets',
    //     clickable: true,
    //   },
    //   navigation: {
    //     nextEl: '.card02 .swiper-button-next',
    //     prevEl: '.card02 .swiper-button-prev',
    //   },
    //   breakpoints: {
    //     768: {
    //       autoHeight: false,
    //       slidesPerView: '2',
    //     }
    //   },
    // });

    // const bullets = document.querySelectorAll('.swiper-pagination-bullet');

    // bullets.forEach((bullet, index) => {
    //   //  console.log(bullet)
    //   if (index === bullets.length - 1) {
    //     console.log(bullet)
    //     const observer = new MutationObserver(records => {
    //       if (bullet.classList.contains('swiper-pagination-bullet-active')) {
    //         bullets[index - 1].classList.add('swiper-pagination-bullet-active')
    //       } else {
    //         // bullets[index - 1].classList.remove('swiper-pagination-bullet-active')

    //       }
    //     })
    //     observer.observe(bullet, {
    //       attributes: true
    //     })
    //   }
    // });

    // //数を合わせるために追加
    // const swiperPagination = document.querySelector('.swiper-pagination');
    // let span = document.createElement('span');
    // span.classList.add('swiper-pagination-bullet')

    // if (mediaQuery.matches) {
    // }else{
    //   swiperPagination.appendChild(span);

    // }

    const pagetop_btn = document.querySelector(".pagetop");

    // .pagetopをクリックしたら
    pagetop_btn.addEventListener("click", scroll_top);

    // ページ上部へスムーズに移動
    function scroll_top() {
      window.scroll({
        top: 0,
        behavior: "smooth"
      });
    }

    // スクロールされたら表示
    window.addEventListener("scroll", scroll_event);

    function scroll_event() {
      if (window.pageYOffset > 100) {
        pagetop_btn.style.opacity = "1";
      } else if (window.pageYOffset < 100) {
        pagetop_btn.style.opacity = "0";
      }
    }

    const currentUrlCopy = document.querySelector('.currentUrlCopy');
    currentUrlCopy.addEventListener('click', () => {
      copyUrl(currentUrlCopy);
      // alert('tests')
    })

    function copyUrl(currentUrlCopy) {
      const currentUrl = window.location.href; // 現在のURLを取得
      navigator.clipboard.writeText(currentUrl) // クリップボードに書き込む
        .then(() => {
          console.log('URL copied to clipboard:', currentUrl);
          currentUrlCopy.classList.add('active');
          setTimeout(() => {
            currentUrlCopy.classList.remove('active');

          }, 1500)

        })
        .catch(err => {
          console.error('Failed to copy URL to clipboard:', err);
          // コピーが失敗した場合の処理をここに追加する（オプション）
        });
    }



    const menuBox = document.querySelector('.menuBox');
    const menuLnk = menuBox.querySelectorAll('ul > li > a');
    const menuBtn = menuBox.querySelector('.menu');
    let menuFlg = false;

    menuBtn.addEventListener('click', () => {
      if (menuFlg) {
        menuFlg = false;
        menuBox.classList.remove('active');

      } else {
        menuFlg = true;
        menuBox.classList.add('active');


      }
    })

    menuLnk.forEach((link)=>{
      link.addEventListener('click',()=>{
        menuFlg = false;
        menuBox.classList.remove('active');

      })
    })

    const menuLinkAbout = menuBox.querySelector('.about');
    const menuLinkAdvisor = menuBox.querySelector('.advisor');
    const menuLinkOfficialPartner = menuBox.querySelector('.officialPartner');
    const menuLinkFaq = menuBox.querySelector('.faq');

    ScrollTrigger.create({
      trigger: "section#mv",
      start: "top center",
      endTrigger: "section#advisor",
      end: "top center",
      // markers: true,
      onToggle: (self) => {
        if (self.isActive) {
          menuLinkAbout.classList.add("active");
        } else {
          menuLinkAbout.classList.remove("active");
        }
      },
    });

    ScrollTrigger.create({
      trigger: "section#advisor",
      start: "top center",
      endTrigger: "section#officialPartner",
      end: "top center",
      // markers: true,
      onToggle: (self) => {
        if (self.isActive) {
          menuLinkAdvisor.classList.add("active");
        } else {
          menuLinkAdvisor.classList.remove("active");
        }
      },
    });

    ScrollTrigger.create({
      trigger: "section#officialPartner",
      start: "top center",
      endTrigger: "div#faq",
      end: "top center",
      // markers: true,
      onToggle: (self) => {
        if (self.isActive) {
          menuLinkOfficialPartner.classList.add("active");
        } else {
          menuLinkOfficialPartner.classList.remove("active");
        }
      },
    });

    ScrollTrigger.create({
      trigger: "div#faq",
      start: "top center",
      endTrigger: "footer",
      end: "top center",
      // markers: true,
      onToggle: (self) => {
        if (self.isActive) {
          menuLinkFaq.classList.add("active");
        } else {
          menuLinkFaq.classList.remove("active");
        }
      },
    });



    const scorrllLinks = document.querySelectorAll('a[href^="#"]');
    scorrllLinks.forEach((scorrllLink) => {
      scorrllLink.addEventListener("click", (e) => {
        e.preventDefault();
        const hrefLink = scorrllLink.getAttribute("href");
        const targetContent = document.getElementById(hrefLink.replace("#", ""));
        const rectTop = targetContent.getBoundingClientRect().top;
        const positionY = window.pageYOffset;
        const target = rectTop + positionY;
        window.scrollTo({
          top: target,
          behavior: "smooth",
        });
      });
    });







    function createModal(id, text, bgBox) {
      // モーダルの作成
      const modal = document.createElement('div');
      modal.classList.add('modal');
      modal.id = id;
      modal.setAttribute('popover', 'auto');

      // .itemの作成
      const item = document.createElement('div');
      item.classList.add('item');

      // ボタンの作成
      const button = document.createElement('button');
      button.setAttribute('popovertarget', id);
      button.setAttribute('popovertargetaction', 'hide');

      // .paragraphの作成
      const paragraph = document.createElement('div');
      paragraph.classList.add('paragraph');

      // pタグの作成
      const p = document.createElement('p');
      p.textContent = text;

      // aタグの作成
      const link = document.createElement('a');
      link.href = 'https://note.com/project_hatafure';
      link.target = '_blank';
      link.classList.add('linkBtn');

      // spanタグの作成
      const span = document.createElement('span');
      span.textContent = '活動をみる';

      // DOMツリーに要素を追加
      paragraph.appendChild(p);
      item.appendChild(button);
      item.appendChild(paragraph);
      link.appendChild(span);
      item.appendChild(link);
      modal.appendChild(item);


      button.addEventListener('click',()=>{
        if(clickFlg){
          clickFlg = false;
          bgBox.remove();
        }
      });


      return modal;
    }

    let clickFlg = false;

    function createModalBtn(id) {
      // ボタンの作成
      const button = document.createElement('button');
      button.setAttribute('popovertarget', id);
      button.setAttribute('popovertargetaction', 'show');

      return button;
    }

    const tileSlides = document.querySelectorAll('.tile-slide');
    tileSlides.forEach((tileSlide, index) => {

      const paragraph = tileSlide.querySelector('article .paragraph > p').textContent;

      const bgBox = document.createElement("div");
      bgBox.classList.add('bgBox');

      const modal = createModal(`modal${index}`, paragraph, bgBox);
      // モーダルを追加
      document.body.appendChild(modal);

      // オープンボタン
      const btn = createModalBtn(`modal${index}`);
      // ボタンを追加
      tileSlide.appendChild(btn);



      btn.addEventListener('click',()=>{
        if(!clickFlg){
          clickFlg = true;
          document.body.appendChild(bgBox)
          if (!HTMLElement.prototype.hasOwnProperty("popover")) {
            modal.style.opacity = '1';
            modal.classList.add('active');
            console.log(modal)
          }
        }else{
          clickFlg = false;
          if (!HTMLElement.prototype.hasOwnProperty("popover")) {
            modal.style.opacity = '0';
            modal.classList.remove('active');
            console.log(modal)
          }
          
        }
      })

      bgBox.addEventListener('click',()=>{
        if(clickFlg){
          clickFlg = false;
          bgBox.remove();
          if (!HTMLElement.prototype.hasOwnProperty("popover")) {
            modal.style.opacity = '0';
            modal.classList.remove('active');
            console.log(modal)

          }
        }
      });
  
    });






    if (HTMLElement.prototype.hasOwnProperty("popover")) {
      // サポートされている
      // console.log("ok")
    } else {
      // サポートされていない
      // console.log("NG")
      const modals = document.querySelectorAll('.modal');
      const tileBtn = document.querySelectorAll('.tile-slide > button');


      tileBtn.forEach((btn)=>{
        btn.addEventListener("click",()=>{

          modals.forEach((modal)=>{
            const closeBtn = modal.querySelector('button');

            closeBtn.addEventListener("click",()=>{
              modal.style.opacity = '0';
              modal.classList.remove('active');
              clickFlg = false;
            })
            
            if(modal.getAttribute('id').toString() == btn.getAttribute('popovertarget')){
                modal.style.opacity = '1';
                modal.classList.add('active');
                
             
            }
          })
        })
      })


      
    }



    // ▼ Cookieの同意ポップアップ
    const expire = 365; // 有効期限（日）
    let cc = document.querySelector(".box-cookieConsent");
    let ca = document.querySelector("#cookieAgree");
    const flag = localStorage.getItem("displayCookieConsent");
    if (flag != null) {
      const data = JSON.parse(flag);
      if (data["value"] == "true") {
        popup();
      } else {
        const current = new Date();
        if (current.getTime() > data["expire"]) {
          setWithExpiry("displayCookieConsent", "true", expire);
          popup();
        }
      }
    } else {
      setWithExpiry("displayCookieConsent", "true", expire);
      popup();
    }
    ca.addEventListener("click", () => {
      cc.classList.add("-hide");
      setWithExpiry("displayCookieConsent", "false", expire);
    });

    function setWithExpiry(key, value, expire) {
      const current = new Date();
      expire = current.getTime() + expire * 24 * 3600 * 1000;
      const item = {
        value: value,
        expire: expire,
      };
      localStorage.setItem(key, JSON.stringify(item));
    }

    function popup() {
      cc.classList.add("-show");
    }
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new Main();
});